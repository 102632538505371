import { commonRoutes } from "routes/route.common";

export const subscriptionPurchaseDomainRoutes = {
  base: "subscriptionPurchase",
  subdomains: {
    subscriptionPurchase: {
      base: "subscriptionPurchases",
    },
  },
};

// SubscriptionPurchase routes
export const baseSubscriptionPurchaseRoute = (): string =>
  `/${subscriptionPurchaseDomainRoutes.base}/`;
export const listSubscriptionPurchaseRoute = (): string =>
  `/${subscriptionPurchaseDomainRoutes.base}/${subscriptionPurchaseDomainRoutes.subdomains.subscriptionPurchase.base}`;
export const createSubscriptionPurchaseRoute = (): string =>
  `/${subscriptionPurchaseDomainRoutes.base}/${subscriptionPurchaseDomainRoutes.subdomains.subscriptionPurchase.base}/${commonRoutes.create}`;

// add subdomain routes here
