


import { IMenuItem } from "interfaces/menu-item.interface";
import { getTranslatedText } from "localization";
import {
  baseSubscriptionPurchaseRoute,
  listSubscriptionPurchaseRoute,
} from "../routes/subscriptionPurchase.base.route";

export const subscriptionPurchaseDomainMenuItem: IMenuItem = {
  title: getTranslatedText("subscriptionPurchase"),
  path: baseSubscriptionPurchaseRoute(),
  iconName:  "carbon:purchase",
  // children: [
  //   {
  //     title: getTranslatedText("subscriptionPurchase"),
  //     path: listSubscriptionPurchaseRoute(),
  //   },
  //   // TODO: import subdomain menu items
  // ],
};
