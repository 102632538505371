import { createSlice } from "@reduxjs/toolkit";
import { commonInitialState, commonReducers } from "context/slices";

const { actions, reducer } = createSlice({
  name: "subscriptionPurchase",
  initialState: {
    ...commonInitialState,
  },
  reducers: {
    ...commonReducers,
  },
});

export const subscriptionPurchaseActions = actions;
export const subscriptionPurchaseSlice = reducer;
