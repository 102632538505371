const locale = {
  subscriptionPurchase: "Subscription Purchase",
  subscriptionPurchases: "Subscription Purchases",
  create: "Subscription Purchase",
  update: "Update Subscription Purchase",
  delete: "Delete Subscription Purchase",
  view: "View Subscription Purchase",
  list: "List Subscription Purchases",
  createdSuccessfully: "Subscription Purchase created successfully",
  updatedSuccessfully: "Subscription Purchase updated successfully",
  deleteConfirm: "Are you sure you want to delete: ",
  deletedSuccessfully: "Subscription Purchase deleted successfully",

  organization: "Organization",
  purchaser: "Purchaser",
  product: "Product",
  currency: "Currency",
  recurringInterval: "Recurring Interval",
  discountRate: "Discount Rate",
};

export default locale;
