import { Route, Routes } from "react-router-dom";

import { Create} from "../pages";

import { SubscriptionPurchaseLayout } from "../layouts/SubscriptionPurchaseLayout";
import { commonRoutes } from "routes/route.common";

export const SubscriptionPurchaseRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<SubscriptionPurchaseLayout />}>
        <Route index element={<Create />} />
        <Route path={`/${commonRoutes.create}`} element={<Create />} />
      </Route>
    </Routes>
  );
};
