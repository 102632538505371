import subscriptionPurchaseLocale from "../subdomains/subscriptionPurchase/locales/en";

const locale = {
  ////////////////////////////////////////////////////////////////
  // domain
  ////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////
  // subDomains
  ////////////////////////////////////////////////////////////////

  subscriptionPurchase: {
    ...subscriptionPurchaseLocale,
  },
};

export default locale;
