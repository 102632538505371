const locale = {
  subscriptionPurchase: "SubscriptionPurchase",
  subscriptionPurchases: "SubscriptionPurchases",
  create: "Create SubscriptionPurchase",
  update: "Update SubscriptionPurchase",
  delete: "Delete SubscriptionPurchase",
  view: "View SubscriptionPurchase",
  list: "List SubscriptionPurchases",
  createdSuccessfully: "SubscriptionPurchase created successfully",
  updatedSuccessfully: "SubscriptionPurchase updated successfully",
  deleteConfirm: "Are you sure you want to delete: ",
  deletedSuccessfully: "SubscriptionPurchase deleted successfully",
};

export default locale;
