import {
  Autocomplete,
  Button,
  CircularProgress,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import {
  useSubscriptionPurchaseWithMoneyTransferMutation,
  useOrganizationListQuery,
} from "../context/subscriptionPurchase.api";
import { useFormik } from "formik";
import { useAppDispatch } from "context";
import { subscriptionPurchaseActions } from "../../../context";
import {
  ISubscriptionPurchaseWithMoneyTransferInput,
  RecurringInterval,
} from "corede-common-cocrm";
import { Currency, IBaseError, IGraphqlVariables } from "corede-common";
import { useEffect } from "react";
import { enqueueSnackbar } from "notistack";
import { getCurrentLanguage } from "localization";
import { validateCreateSubscriptionPurchaseInput } from "../validations/create.validation";
import { t } from "i18next";
import { RequestTransformerHelper } from "validations/request.transformer.helper";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { useUserListQuery } from "apps/main/domains/user/subdomains/user";
import { usePaymentProductsQuery } from "apps/main/domains/paymentProduct/subdomains/paymentProduct";

const Create = () => {
  // general
  const dispatch = useAppDispatch();
  const currentLanguage = getCurrentLanguage();

  // queries

  const {
    data: organizationListData,
    isLoading: organizationListIsLoading,
    error: organizationListError,
  } = useOrganizationListQuery({});

  const {
    data: paymentProductsData,
    isLoading: paymentProductsIsLoading,
    error: paymentProductsError,
  } = usePaymentProductsQuery({});

  // mutations
  const [
    subscriptionPurchaseWithMoneyTransfer,
    {
      data: subscriptionPurchaseWithMoneyTransferData,
      isLoading: subscriptionPurchaseWithMoneyTransferIsLoading,
      error: subscriptionPurchaseWithMoneyTransferError,
    },
  ] = useSubscriptionPurchaseWithMoneyTransferMutation();

  // constants
  const { values, handleSubmit, handleBlur, setFieldValue, errors, resetForm } =
    useFormik({
      initialValues: {
        input: {
          organizationId: "",
          purchaserUserId: "",
          productId: "",
          currency: Currency.USD,
          recurringInterval: RecurringInterval.monthly,
          discountRate: 0,
        },
      },
      enableReinitialize: true,
      validationSchema: validateCreateSubscriptionPurchaseInput,
      onSubmit: (
        values: IGraphqlVariables<ISubscriptionPurchaseWithMoneyTransferInput>
      ) => {
        const transformedValues =
          RequestTransformerHelper.TransformAllEmptyStringFieldsToUndefined({
            input: values.input,
          });
        subscriptionPurchaseWithMoneyTransfer({
          ...values,
          input: transformedValues,
        });
      },
    });

  const {
    data: userListData,
    isLoading: userListIsLoading,
    error: userListError,
  } = useUserListQuery(
    {
      input: {
        filter: {
          organizationId: values?.input?.organizationId,
        },
      },
    },
    {
      skip: !values?.input?.organizationId,
    }
  );

  // useEffects.success
  useEffect(() => {
    if (subscriptionPurchaseWithMoneyTransferData) {
      console.log(subscriptionPurchaseWithMoneyTransferData, "data");
      resetForm();
      enqueueSnackbar(
        t("main.subscriptionPurchase.subscriptionPurchase.createdSuccessfully"),
        {
          variant: "success",
        }
      );
    }
  }, [subscriptionPurchaseWithMoneyTransferData, resetForm]);

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      subscriptionPurchaseWithMoneyTransferError as IBaseError,
      currentLanguage
    );
  }, [subscriptionPurchaseWithMoneyTransferError, currentLanguage]);

  // useEffects.init
  useEffect(() => {
    dispatch(
      subscriptionPurchaseActions.setTitle(
        t("main.subscriptionPurchase.subscriptionPurchase.create")
      )
    );
    dispatch(subscriptionPurchaseActions.setBackButton(false));
    dispatch(subscriptionPurchaseActions.setRightButton(null));
  }, [dispatch]);

  return (
    <Grid item xs={12}>
      <Stack direction="column" gap={2} mt={2} maxWidth={400}>
        <Autocomplete
          id="organizationId"
          isOptionEqualToValue={(option, value) => option._id === value._id}
          getOptionLabel={(option) => option.name}
          options={(organizationListData as any)?.data || []}
          loading={organizationListIsLoading}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("main.subscriptionPurchase.subscriptionPurchase.organization") + "*"}
              error={!!(errors as any).input?.organizationId}
              helperText={(errors as any).input?.organizationId}
            />
          )}
          value={
            (organizationListData as any)?.data?.find(
              (organization: any) =>
                organization._id === (values as any).input.organizationId
            ) || null
          }
          onChange={(e, value) => {
            setFieldValue("input.organizationId", value?._id);
          }}
          onBlur={() => handleBlur("input.organizationId")}
        />
        <Autocomplete
          id="purchaserUserId"
          isOptionEqualToValue={(option, value) => option._id === value._id}
          getOptionLabel={(option) => option.name + " " + option.surname + " | " + option.email}
          options={(userListData as any)?.data || []}
          loading={userListIsLoading}
          disabled={!values?.input?.organizationId}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("main.subscriptionPurchase.subscriptionPurchase.purchaser") + "*"}
              error={!!(errors as any).input?.purchaserUserId}
              helperText={(errors as any).input?.purchaserUserId}
            />
          )}
          value={
            (userListData as any)?.data?.find(
              (user: any) => user._id === (values as any).input.purchaserUserId
            ) || null
          }
          onChange={(e, value) => {
            setFieldValue("input.purchaserUserId", value?._id);
          }}
          onBlur={() => handleBlur("input.purchaserUserId")}
        />
        <Autocomplete
          id="productId"
          isOptionEqualToValue={(option, value) => option._id === value._id}
          getOptionLabel={(option) => option.name}
          options={(paymentProductsData as any)?.data || []}
          loading={paymentProductsIsLoading}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("main.subscriptionPurchase.subscriptionPurchase.product") + "*"}
              error={!!(errors as any).input?.productId}
              helperText={(errors as any).input?.productId}
            />
          )}
          value={
            (paymentProductsData as any)?.data?.find(
              (product: any) => product._id === (values as any).input.productId
            ) || null
          }
          onChange={(e, value) => {
            setFieldValue("input.productId", value?._id);
          }}
          onBlur={() => handleBlur("input.productId")}
        />

        <Autocomplete
          id="currency"
          options={Object.values(Currency)}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("main.subscriptionPurchase.subscriptionPurchase.currency")}
              error={!!(errors as any).input?.currency}
              helperText={(errors as any).input?.currency}
            />
          )}
          value={(values as any).input.currency}
          onChange={(e, value) => {
            setFieldValue("input.currency", value);
          }}
          onBlur={() => handleBlur("input.currency")}
        />

        <Autocomplete
          id="recurringInterval"
          options={Object.values(RecurringInterval)}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("main.subscriptionPurchase.subscriptionPurchase.recurringInterval")}
              error={!!(errors as any).input?.recurringInterval}
              helperText={(errors as any).input?.recurringInterval}
            />
          )}
          getOptionLabel={(option) => option.charAt(0).toUpperCase() + option.slice(1)}
          value={(values as any).input.recurringInterval}
          onChange={(e, value) => {
            setFieldValue("input.recurringInterval", value);
          }}
          onBlur={() => handleBlur("input.recurringInterval")}
        />

        <TextField
          id="discountRate"
          label={t("main.subscriptionPurchase.subscriptionPurchase.discountRate")}
          type="number"
          value={(values as any).input.discountRate}
          onChange={(e) => {
            setFieldValue("input.discountRate", Number(e.target.value));
          }}
          onBlur={() => handleBlur("input.discountRate")}
          error={!!(errors as any).input?.discountRate}
          helperText={(errors as any).input?.discountRate}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSubmit()}
          disabled={subscriptionPurchaseWithMoneyTransferIsLoading}
        >
          {subscriptionPurchaseWithMoneyTransferIsLoading ? (
            <CircularProgress size="1rem" sx={{ mr: 2 }} />
          ) : (
            ""
          )}
          Create
        </Button>
      </Stack>
    </Grid>
  );
};

export default Create;
