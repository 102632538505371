import {
  subscriptionPurchaseWithMoneyTransferQuery,
  ISubscriptionPurchaseWithMoneyTransferInput,
  ISubscriptionPurchaseWithMoneyTransferRequest,
  ISubscriptionPurchaseWithMoneyTransferResponse,
  ISubscriptionPurchaseWithMoneyTransferResult,
  organizationListQuery,
  IOrganizationListRequest,
  IOrganizationListResponse,
  IOrganizationListResult,
  IOrganizationListInput,
} from "corede-common-cocrm";
import { commonApi } from "context/commonApi";
import { EndpointQueryBuilder } from "apps/helper/endpoint-query.builder";
import { subscriptionPurchaseTags } from "./subscriptionPurchase.tags";
import { IGraphqlVariables } from "corede-common";

export const subscriptionPurchaseApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // queries
    organizationList: builder.query<
      IOrganizationListResponse,
      IGraphqlVariables<IOrganizationListInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IOrganizationListRequest,
        IOrganizationListResponse,
        IOrganizationListResult,
        IOrganizationListInput
      >({
        query: organizationListQuery,
      })
    ),

    // mutation
    subscriptionPurchaseWithMoneyTransfer: builder.mutation<
      ISubscriptionPurchaseWithMoneyTransferResponse,
      IGraphqlVariables<ISubscriptionPurchaseWithMoneyTransferInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ISubscriptionPurchaseWithMoneyTransferRequest,
        ISubscriptionPurchaseWithMoneyTransferResponse,
        ISubscriptionPurchaseWithMoneyTransferResult,
        ISubscriptionPurchaseWithMoneyTransferInput
      >({
        query: subscriptionPurchaseWithMoneyTransferQuery,
        invalidatesTags: [
          subscriptionPurchaseTags.subscriptionPurchases,
          subscriptionPurchaseTags.subscriptionPurchase,
        ],
      })
    ),
  }),
});

export const {
  useSubscriptionPurchaseWithMoneyTransferMutation,
  useOrganizationListQuery,
} = subscriptionPurchaseApi;
