

import { SubscriptionPurchaseRouter } from "../subdomains/subscriptionPurchase/routes/SubscriptionPurchaseRouter";
import { SubscriptionPurchaseDomainLayout } from "../layouts";
import { subscriptionPurchaseDomainRoutes } from "./subscriptionPurchase.base.route";
import { Route, Routes } from "react-router-dom";

export const SubscriptionPurchaseDomainRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<SubscriptionPurchaseDomainLayout />}>
        <Route index element={<SubscriptionPurchaseRouter />} />
        <Route
          path={`/${subscriptionPurchaseDomainRoutes.subdomains.subscriptionPurchase.base}/*`}
          element={<SubscriptionPurchaseRouter />}
        />
        {/*
          // TODO: import subdomains
        */}
      </Route>
    </Routes>
  );
};
