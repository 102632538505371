import subscriptionPurchaseLocale from "../subdomains/subscriptionPurchase/locales/tr";

const locale = {
  ////////////////////////////////////////////////////////////////
  // domain
  ////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////
  // subDomains
  ////////////////////////////////////////////////////////////////

  subscriptionPurchase: {
    ...subscriptionPurchaseLocale,
  },

  // TODO: import subdomain locales
};

export default locale;
